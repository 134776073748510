<template>
  <div>
    <v-layout wrap>
      <v-flex xs12>
        <v-layout wrap py-10 v-if="shop">
          <v-flex v-if="shop.gallery">
            <OwlCarousel
              :nav="false"
              :dots="false"
              :loop="true"
              :autoplay="true"
              :autoplayTimeout="3000"
              :responsive="owlResponsive"
            >
              <template xs12 sm10 v-for="(list, l) in shop.gallery">
                <v-layout wrap justify-center v-if="list" :key="l">
                  <v-flex>
                    <img
                      height="400px"
                      :src="mediaUURL + list"
                      eager
                      style="object-fit: cover; cursor: pointer"
                    />
                  </v-flex>
                </v-layout>
              </template> </OwlCarousel
          ></v-flex>
        </v-layout>
        <v-layout
          wrap
          justify-center
          style="
            background: var(--green,linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #ceffe2 100%)); ;"
        >
          <v-flex xs11>
            <v-layout wrap justify-center pb-10>
              <v-flex xs12 text-center>
                <span
                  style="
                    color: #000000;
                    font-family: poppinsbold;
                    font-size: 25px;
                  "
                  >Our Story</span
                >
              </v-flex>
            </v-layout>
            <v-layout wrap justify-center pb-10>
              <v-flex xs12 sm4>
                <v-img src="./../../assets/images/ourstory.png"></v-img>
              </v-flex>
              <v-flex
                xs12
                sm8
                text-left
                pl-xs-0
                pl-sm-10
                pl-md-10
                pl-lg-10
                pl-xl-10
                v-if="shop.story"
              >
                <span
                  style="
                    color: #000000;
                    font-family: RobotoRegular;
                    font-size: 14px;
                    line-height: 25px;
                    letter-spacing: 1px;
                    text-align: justify;
                  " v-html="shop.story"
                  ></span
                >
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
export default {
  props:["shop"],
  data() {
    return {
      appLoading: false,
      ServerError: false,
      showSnackBar: false,
      timeout: 5000,
      msg: null,
      lists: [
        {
          image: require("./../../assets/images/Frame1.png"),
        },
        {
          image: require("./../../assets/images/Frame2.png"),
        },
        {
          image: require("./../../assets/images/Frame3.png"),
        },
        {
          image: require("./../../assets/images/Frame4.png"),
        },
      ],
      owlResponsive: {
        0: { items: 1, nav: false },
        600: { items: 2, nav: false },
        960: { items: 3, nav: false },
        1264: { items: 4, nav: false },
        1920: { items: 6, nav: false },
      },
    };
  },
};
</script>