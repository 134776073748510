<template>
  <div>
    <v-layout wrap>
      <v-flex xs12>
        <v-layout wrap justify-center py-10 v-if="ratings.length > 0">
          <v-flex xs12 text-center>
            <span
              style="color: #000000; font-family: poppinsbold; font-size: 25px"
              >Our Customer Feedbacks</span
            >
          </v-flex>
        </v-layout>
        <v-layout wrap justify-center>
          <v-flex xs12 sm6 md3 pa-2 v-for="(item, i) in ratings" :key="i">
            <v-card>
              <v-layout wrap justify-center pa-5>
                <v-flex xs12 text-left pb-5>
                  <v-rating
                    color="#30B868"
                    background-color="#30B868"
                    readonly
                    v-model="item.rating"
                    half-increments
                    hover
                    small
                  ></v-rating>
                </v-flex>
                <v-flex xs12 text-left>
                  <span
                    v-if="!item.showFullText"
                    style="font-family: opensansregular; color: #000000"
                  >
                    {{ truncateText(item.review, 100) }}
                  </span>
                </v-flex>
                <v-flex
                  xs12
                  text-right
                  v-if="item.review.length > 100"
                >
                  <v-btn text tile depressed @click="toggleText(i)">
                    <span
                      style="
                        font-family: opensansbold;
                        font-size: 13px;
                        text-transform: none;
                        color: #00000;
                      "
                      >{{ item.showFullText ? 'Read Less' : 'Read More' }}</span
                    >
                  </v-btn>
                </v-flex>
                <v-flex xs12 text-left v-if="item.showFullText">
                  <span style="font-family: opensansregular; color: #000000">{{
                    item.review
                  }}</span>
                </v-flex>
                <!-- <v-flex
                  xs12
                  text-right
                  v-if="item.review.length > 100 && item.showFullText"
                >
                  <v-btn text @click="expandText(i)">
                    <span
                      style="
                        font-family: opensansbold;
                        font-size: 13px;
                        text-transform: none;
                        color: #00000;
                      "
                      >Read Less</span
                    >
                  </v-btn>
                </v-flex> -->
                <v-flex xs12 pb-5 pt-10>
                  <v-layout wrap>
                    <v-flex xs2 sm1>
                      <!-- <v-img :src="item.image"></v-img> -->
                      <v-avatar size="35px" color="grey">
                        <v-icon dark> mdi-account-circle </v-icon>
                      </v-avatar>
                    </v-flex>
                    <v-flex xs10 sm11 text-left pl-5>
                      <span style="font-family: opensansbold; color: #000000">{{
                        item.userId.name
                      }}</span>
                      <br />
                      <!-- <span
                        style="
                          font-family: opensanslight;
                          color: #000000;
                          font-size: 12px;
                        "
                        >{{ item.place }}</span
                      > -->
                    </v-flex>
                  </v-layout>
                </v-flex>
              </v-layout>
            </v-card>
          </v-flex>
        </v-layout>
        <v-layout wrap>
          <v-flex xs12>
            <v-img src="./../../assets/images/blog.png">
              <template v-slot:placeholder>
                <ImageLoader />
              </template>
            </v-img>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
export default {
  props: ["ratings"],
  data() {
    return {
      appLoading: false,
      ServerError: false,
      showSnackBar: false,
      timeout: 5000,
      msg: null,

      items: [
        {
          title:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam, purus sit amet luctus venenatis, lectus magna",
          image: require("./../../assets/images/fb1.png"),
          name: "Kartik Bansal",
          place: "Kota, Rajasthan",
        },
        {
          title:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam, purus sit amet luctus venenatis, lectus magna",
          image: require("./../../assets/images/fb2.png"),
          name: "Anil Dvivedi",
          place: "New Delhi",
        },
        {
          title:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam, purus sit amet luctus venenatis, lectus magna",
          image: require("./../../assets/images/fb3.png"),
          name: "Srijan Sirasikar",
          place: "Bhadrak",
        },
      ],
    };
  },
  methods: {
    truncateText(text, maxLength) {
      return text.length > maxLength ? text.substring(0, maxLength) + '...' : text;
    },
    toggleText(index) {
      this.$set(this.ratings, index, {
        ...this.ratings[index],
        showFullText: !this.ratings[index].showFullText,
      });
    },
  },
};
</script>