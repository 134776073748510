<template>
  <div>
    <v-layout wrap>
      <v-flex xs12 py-10 class="hidden-sm-and-down">
        <span style="color: #000000; font-family: poppinsbold; font-size: 25px"
          >Our Products</span
        >
      </v-flex>
      <v-flex xs12 pt-10 class="hidden-md-and-up">
        <span style="color: #000000; font-family: poppinsbold; font-size: 25px"
          >Our Products</span
        >
      </v-flex>
    </v-layout>
    <v-layout wrap justify-center>
      <v-flex xs12 sm11 pt-5>
        <v-layout wrap justify-space-between>
          <v-flex xs12 sm12 md8>
            <!-- <v-layout wrap justify-start>
              <v-flex xs6 sm3 md2 pr-10> -->
            <v-sheet class="px-4">
              <v-chip-group center-active>
                <v-chip
                  class="ma-2 px-4"
                  color="#30B868"
                  @click="getProducts(null)"
                  :outlined="catId == '' || catId == null ? false : true"
                >
                  <span
                    :style="catId == '' || catId == null ? 'color: white' : 'color: black'"
                    style="font-family: RobotoRegular; letter-spacing: 1px;"
                  >
                    All</span
                  >
                </v-chip>
                <v-chip
                  v-for="(item, i) in category"
                  :key="i"
                  class="ma-2 px-4"
                  color="#30B868"
                  :outlined="catId != item._id ? true : false"
                  @click="getProductsFilter(item._id), idCat=item._id"
                >
                  <span
                    :style="catId == null || catId != item._id ? 'color: black' : 'color: white'"
                    style="font-family: RobotoRegular; letter-spacing: 1px;"
                  >
                    {{ item.name }}</span
                  >
                </v-chip>
              </v-chip-group>
            </v-sheet>
            <!-- </v-flex>
            </v-layout> -->
          </v-flex>
          <v-spacer></v-spacer>
          <v-flex xs12 sm12 md4>
            <v-layout wrap justify-end>
              <v-flex>
                <v-text-field
                  rounded
                  background-color="#F1F1F1"
                  solo
                  flat
                  dense
                  v-model="keyword"
                  append-icon="mdi-magnify"
                  label="Search"
                ></v-text-field
              ></v-flex>
              <v-flex>
                <v-btn dark color="#30B868" @click="getSearch()"
                  ><span
                    style="text-transform: none; font-family: poppinssemibold"
                    >Search
                  </span></v-btn
                >
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-flex>
      <v-flex xs11 v-if="product.length > 0">
        <v-layout wrap justify-start>
          <v-flex xs12 sm6 md3 pa-5 v-for="(list, l) in product" :key="l">
            <v-card outlined height="420px">
              <router-link :to="'/productDetails?productName=' + list.productname + '&id=' + list._id">
              <v-layout wrap justify-center py-5>
                <v-flex xs12>
                  <v-layout wrap justify-center>
                    <v-flex xs6 sm6 text-left v-if="list.offerPercentage>1">
                      <v-img
                        height="30px"
                        width="70px"
                        src="./../../assets/icons/tag.png"
                      >
                        <span
                          style="
                            color: #ffffff;
                            font-size: 13px;
                            font-family: opensanssemibold;
                          "
                        >
                          &nbsp; {{ list.offerPercentage.toFixed() }} %</span
                        >
                      </v-img>
                    </v-flex>
                    <v-flex xs6 sm6 pl-15>
                      <!-- <v-icon>mdi-heart-outline</v-icon> -->
                    </v-flex>
                    <v-flex xs12 :pt-9="list.offerPercentage>1 ? false : true">
                      <v-img
                        contain
                        height="160px"
                        :src="mediaUURL + list.photos[0]"
                      ></v-img>
                    </v-flex>
                    <v-flex xs12>
                      <span
                        style="
                          color: #bcb4b4;
                          font-family: poppinsmedium;
                          font-size: 12px;
                        "
                        >{{ list.category.name }}</span
                      >
                    </v-flex>
                    <v-flex xs12>
                      <span
                        style="
                          color: #000000;
                          font-family: poppinsmedium;
                          font-size: 16px;
                          text-transform: uppercase;
                        "
                        >{{ list.productname }}</span
                      >
                    </v-flex>
                    <v-flex xs12>
                      <span
                        style="
                          color: #000000;
                          font-family: poppinsmedium;
                          font-size: 14px;
                        "
                        >{{ list.size }}</span
                      >
                    </v-flex>
                    <v-flex xs12 pt-5>
                      <span
                        style="
                          color: #30b868;
                          font-family: RobotoMedium;
                          font-size: 15px;
                        "
                        >₹ {{ list.offerPrice }} &nbsp;</span
                      >
                      <span v-if="list.offerPrice != list.price"
                        style="
                          color: #8b8b8b;
                          font-family: RobotoMedium;
                          font-size: 13px;
                          text-decoration: line-through;
                        "
                      >
                      ₹ {{ list.price }}</span
                      >
                    </v-flex>
                    <v-flex xs6 sm6 text-center pt-3>
                      <v-btn color="#30B868" dark block :to="'/productDetails?productName=' + list.productname + '&id=' + list._id">
                        <span
                          style="
                            font-family: opensanssemibold;
                            text-transform: none;
                            font-size: 14px;
                          "
                          >Buy Now</span
                        ></v-btn
                      >
                    </v-flex>
                  </v-layout>
                </v-flex>
              </v-layout>
            </router-link>
            </v-card>
          </v-flex>
        </v-layout>
        <v-layout wrap justify-center pt-2>
          <v-flex xs12>
            <div class="text-center pb-5" v-if="pages > 1">
              <v-pagination
                :length="pages"
                :total-visible="7"
                v-model="currentPage"
                color="#30B868"
                circle
              ></v-pagination>
            </div>
          </v-flex>
        </v-layout>
      </v-flex>
      <v-flex xs12 sm12 v-else align-self-center pt-5>
        <span
          style="
            font-family: poppinsregular;
            font-size: 25px;
            color: #000000;
            cursor: pointer;
          "
          >Oops! No Products Found</span
        >
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import axios from "axios";
export default {
  data() {
    return {
      appLoading: false,
      ServerError: false,
      showSnackBar: false,
      timeout: 5000,
      msg: null,
      page: 1,
      currentPage: 1,
      pages: 0,
      limit: 16,
      category: [],
      catId: "",
      idCat:"",
      keyword:"",
      items: [
        {
          name: "All",
        },
        {
          name: "Personal Care",
        },
        {
          name: "Household",
        },
        {
          name: "Health",
        },
        {
          name: "Groceries",
        },
      ],
      lists: [
        {
          image: require("./../../assets/images/product1.png"),
          category: "Health",
          name: "Honey (Organic)",
          quantity: "500 Gm",
          offerprice: "₹ 520",
          price: "(₹ 620)",
        },
        {
          image: require("./../../assets/images/product2.png"),
          category: "Groceries ",
          name: "Pure Coconut Oil ",
          quantity: "500 ml",
          offerprice: "₹ 250",
          price: "(₹ 320)",
        },
        {
          image: require("./../../assets/images/product3.png"),
          category: "Personal Care",
          name: "Softening Creamy Soap Bar",
          quantity: "100 Gm",
          offerprice: "₹ 120",
          price: "(₹ 180)",
        },
        {
          image: require("./../../assets/images/product4.png"),
          name: "Sesame Oil",
          category: "Groceries ",
          quantity: "500 ml",
          offerprice: "₹ 480",
          price: "(₹ 560)",
        },
        {
          image: require("./../../assets/images/product5.png"),
          category: "Personal Care ",
          name: "Herbal Charcoal Soap",
          quantity: "100 Gm",
          offerprice: "₹ 220",
          price: "(₹ 280)",
        },
        {
          image: require("./../../assets/images/product1.png"),
          category: "Health",
          name: "Honey (Organic)",
          quantity: "500 Gm",
          offerprice: "₹ 520",
          price: "(₹ 620)",
        },
        {
          image: require("./../../assets/images/product2.png"),
          category: "Groceries ",
          name: "Pure Coconut Oil ",
          quantity: "500 ml",
          offerprice: "₹ 250",
          price: "(₹ 320)",
        },
        {
          image: require("./../../assets/images/product3.png"),
          category: "Personal Care",
          name: "Softening Creamy Soap Bar",
          quantity: "100 Gm",
          offerprice: "₹ 120",
          price: "(₹ 180)",
        },
      ],
      product: [],
    };
  },
  watch: {
    currentPage() {
      this.getProducts();
      this.$store.commit("changeCurrentPage", this.currentPage);
    },
  },
  beforeMount() {
    this.getCategory();
    if (this.$store.state.currentPage) {
      this.currentPage = this.$store.state.currentPage;
    this.getProducts();
    }
    else{
    this.getProducts();
    }
  },
  methods: {
    getSearch(){
      if(this.catId=="")
      {
        this.currentPage=1
        this.getProducts()
      }
      else
      {
        this.currentPage=1
      this.getProductsFilter(this.idCat)
      }
      
    },
    getCategory() {
      this.appLoading = true;
      axios({
        url: "/category",
        method: "GET",
        // headers: {
        //   token: localStorage.getItem("token"),
        // },
        params:{
          sellerName: this.$route.query.sellerName
        }
      })
        .then((response) => {
          this.appLoading = false;
          this.category = response.data.data;
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    getProducts() {
      this.appLoading = true;
      axios({
        url: "/products",
        method: "POST",
        data: {
          sellerName: this.$route.query.sellerName,
          limit: this.limit,
          page: this.currentPage,
          category:null,
          keyword:this.keyword
        },
        // headers: {
        //   token: localStorage.getItem("token"),
        // },
      })
        .then((response) => {
          this.appLoading = false;
          this.product = response.data.data;
          this.catId = ""
          this.pages = Math.ceil(response.data.totalLength / this.limit);
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    getProductsFilter(id){
      this.appLoading = true;
      this.currentPage=1
      // if(id){
      //   this.keyword=""
      // }
      axios({
        url: "/products",
        method: "POST",
        data: {
          sellerName: this.$route.query.sellerName,
          limit: this.limit,
          page: this.currentPage,
          category:id,
          keyword:this.keyword
        },
        // headers: {
        //   token: localStorage.getItem("token"),
        // },
      })
        .then((response) => {
          this.appLoading = false;
          this.catId = id;
          this.product = response.data.data;
          this.pages = Math.ceil(response.data.totalLength / this.limit);
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    }
  },
};
</script>
<style>
.top-left-content {
  position: absolute;
  top: 20px;
  left: 0;
  padding: 5px;
  background-color: #ff7c03;
}
</style>