<template>
  <div>
    <PageLoader v-bind:storage="appLoading" />
    <ServerError v-if="ServerError" />
    <v-layout wrap>
      <v-flex xs12>
        <v-carousel
          v-model="slideNo"
          continuous
          cycle
          :interval="slideInterval"
          show-arrows-on-hover
          hide-delimiter-background
          delimiter-icon="mdi-minus"
          :height="sliderHeight"
        >
          <v-carousel-item v-for="(data, d) in shop.caroselImage" :key="d">
            <v-card flat>
              <v-img
                :src="mediaUURL + data"
                :alt="data"
                eager
              >
                <v-layout wrap fill-height>
                  <v-flex xs12 pt-16>
                    <v-layout
                      wrap
                      justify-start
                      pa-5
                      px-xs-0
                      px-sm-0
                      px-md-16
                      px-lg-16
                      px-xl-16
                      pt-16
                      data-aos="zoom-in-up"
                      data-aos-ease="ease"
                      data-aos-duration="1500"
                      data-aos-delay="500"
                    >
                      <v-flex
                        pt-16
                        px-10
                        pb-10
                        xs12
                        sm10
                        md6
                        align-self-center
                        text-left
                        :style="{
                          'line-height':
                            $vuetify.breakpoint.name == 'xs' ? '35px' : '45px',
                        }"
                      >
                        <span
                          style="
                            font-family: RobotoMedium;
                            color: #30b868;
                            text-transform: uppercase;
                            font-size: 18px;
                          "
                          >Welcome to</span
                        >
                        <br />
                        <span
                          style="
                            font-family: poppinsbold;
                            text-transform: uppercase;
                            color: #383838;
                          "
                          :style="{
                            'font-size':
                              $vuetify.breakpoint.name == 'xs'
                                ? '30px'
                                : '50px',
                          }"
                        >
                          {{ shop.shopName }}
                        </span>
                        <br />
                        <span
                          style="
                            font-family: poppinsmedium;
                            color: #000000;
                            font-size: 30px;
                          "
                          v-html="shop.description"
                          ></span
                        >
                        <!-- <v-flex xs12 pt-5>
                          <v-btn
                            color="#30B868"
                            dark
                            large
                            class="text-capitalize"
                            :to="{
                              path: '/',
                              query: {
                                id: '646706684d0353111a33db3e',
                                content: 'Eco Tourism Activites',
                              },
                            }"
                          >
                            <span style="font-family: RobotoRegular"
                              >Shop Now</span
                            >
                          </v-btn>
                        </v-flex> -->
                      </v-flex>
                    </v-layout>
                    <v-layout wrap justify-start pt-16>
                      <v-flex xs12 sm8 md6 lg4 xl3 align-self-end pt-16>
                        <!-- <v-img
                          contain
                          width="700px"
                          src="./../../assets/shade.png"
                        > -->
                          <v-layout wrap justify-center fill-height class="rectangle">
                            <v-flex xs12 sm12 md10 pl-xs-0 pl-xsm-0 pl-md-9 pl-lg-9 pl-xl-9>
                              <v-layout wrap px-5 py-2>
                                <v-flex xs1 sm1 align-self-center>
                                  <v-img
                                    height="22px"
                                    contain
                                    src="./../../assets/icons/map.png"
                                  ></v-img>
                                </v-flex>
                                <v-flex xs6 sm5 md6 text-left px-5 pt-2 v-if="shop.address">
                                  <span
                                    style="
                                      color: #ffffff;
                                      font-size: 14px;
                                      font-family: RobotoRegular;
                                    "
                                    >{{ shop.address.name }}, {{ shop.address.place }}, {{ shop.address.district }}</span
                                  >
                                </v-flex>
                                <v-flex xs1 sm1 pt-8 text-right>
                                  <v-img
                                    src="./../../assets/icons/phone.png"
                                    height="20px"
                                    contain
                                  />
                                </v-flex>
                                <v-flex xs4 sm3 md4 text-right pt-7>
                                  <span
                                    style="
                                      color: #ffffff;
                                      font-size: 14px;
                                      font-family: RobotoRegular;
                                    "
                                    >+91 {{ shop.contactNumber }}</span
                                  >
                                </v-flex>
                              </v-layout>
                            </v-flex>
                          </v-layout>
                        <!-- </v-img> -->
                      </v-flex>
                    </v-layout>
                  </v-flex>
                </v-layout>
                <template v-slot:placeholder>
                  <ImageLoader />
                </template>
              </v-img>
            </v-card>
          </v-carousel-item>
        </v-carousel>
      </v-flex>
    </v-layout>
    <v-layout wrap justify-center>
      <v-flex xs11 sm11 md9 py-10>
        <v-layout wrap justify-center>
          <v-flex xs12 sm6 md3 v-for="(item, i) in items" :key="i">
            <v-layout wrap justify-center>
              <v-flex xs2 sm2>
                <v-img contain height="30px" :src="item.image"></v-img>
              </v-flex>
              <v-flex xs10 sm10 md6 text-left pl-xs-2 pl-sm-2 pl-md-5 pl-lg-5 pl-xl-5>
                <span style="font-family: opensanssemibold; color: #000000">{{
                  item.name
                }}</span>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-flex>
      <v-flex xs12>
        <v-divider></v-divider>
      </v-flex>
    </v-layout>
    <v-layout wrap justify-center>
      <v-flex xs12>
        <Products />
      </v-flex>
    </v-layout>
    <v-layout wrap justify-center>
      <v-flex xs12>
        <OurStory :shop="shop"/>
      </v-flex>
    </v-layout>
    <v-layout wrap justify-center>
      <v-flex xs12>
        <Feedback :ratings="ratings" />
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import axios from "axios";
import Products from "./products";
import OurStory from "./ourStory";
import Feedback from "./feedback";
export default {
  components: {
    Products,
    OurStory,
    Feedback,
  },
  data() {
    return {
      appLoading: false,
      ServerError: false,
      showSnackBar: false,
      timeout: 5000,
      msg: null,
      slideNo: 0,
      slideInterval: 5000,
      shop:[],
      category:[],
      list: [
        {
          image: require("./../../assets/images/ecoshopbanner.png"),
        },
        {
          image: require("./../../assets/images/Frame 4.png"),
        },
        {
          image: require("./../../assets/images/Frame 5.png"),
        },
      ],
      items: [
        {
          image: require("./../../assets/icons/ThumbsUp.png"),
          name: "Top Quality Products",
        },
        {
          image: require("./../../assets/icons/CreditCard.png"),
          name: "Secure Payment ",
        },
        {
          image: require("./../../assets/icons/Headset.png"),
          name: "24/7 Accessibility",
        },
        {
          image: require("./../../assets/icons/Truck.png"),
          name: "Secure & Fast Delivery",
        },
      ],
      ratings:[],
    };
  },
  computed: {
    sliderHeight() {
      var height = "100%";
      if (this.$vuetify.breakpoint.xl) height = "100%";
      if (this.$vuetify.breakpoint.lg) height = "100%";
      if (this.$vuetify.breakpoint.md) height = "100%";
      if (this.$vuetify.breakpoint.sm) height = "100%";
      if (this.$vuetify.breakpoint.xs) height = "100%";
      return height;
    },
  },
  beforeMount(){
    this.getData()
  },
  methods:{
    getData() {
      this.appLoading = true;
      axios({
        url: "/sellerView",
        method: "GET",
        headers: {
          token: localStorage.getItem("token"),
        },
        params: {
          sellerName: this.$route.query.sellerName,
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.shop = response.data.data;
          this.ratings=response.data.ratings
          this.$store.commit("sellerData", response.data.data);
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
  }
};
</script>
<style>
.rectangle {
  height: 100px;
  width: 100%;
  background-color: #555;
}
</style>